import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './assets/logo.png';
import './ProjectSetup.css';

function verifySession(navigate) {
	function navToLogin() {
		navigate('/Login');
	}
	const sessionToken = localStorage.getItem('sessionToken');
	if (sessionToken) {
		const url = new URL('/api/auth/sessionVerify', window.location.origin);
		url.searchParams.append('token', sessionToken);

		fetch(url, {
			method: 'GET',
			headers: { Accept: 'application/json' },
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.expired == true) {
					// Token is expired; navigate to the login page
					console.log('Session expired, please log in again.');
					navToLogin();
				}
			})
			.catch((error) => {
				console.error('Error verifying session: ', error);
			});
	}
}

function ProjectSetup() {
	const [projectName, setProjectName] = useState('');
	const [file, setFile] = useState(null);
	const [fileURL, setFileURL] = useState('');
	const [successfullyUploaded, setSuccessfullyUploaded] = useState('');
	const [nodes, setNodes] = useState([]);
	const [selectedNode, setSelectedNode] = useState(null);
    const [nodeToken, setNodeToken] = useState('');
	const navigate = useNavigate();

	const navToDashboard = () => {
        navigate('/Dashboard/Landing');
    };

    const navToNodeEnroll = () => {
        navigate('/nodeEnroll');
    };

    const navToProjectSetup = () => {
        navigate('/setup')
    }

	// Check the session token when the component loads
	useEffect(() => {
		verifySession(navigate);
		fetchNodes();
	}, []);

	async function fetchNodes() {
		const sessionToken = localStorage.getItem('sessionToken');
		const url = new URL('/api/node/getNodeHierarchy', window.location.origin);
		url.searchParams.append('token', sessionToken);
		url.searchParams.append('levels', 0);
		url.searchParams.append('headID', '');
		try {
			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					Authorization: `Bearer ${sessionToken}`,
				},
			});
			const data = await response.json();
			setNodes(data.children);
		} catch (error) {
			console.error('Error fetching hierarchy:', error);
			console.log('Cannot get hierarchy');
		}
	}

    async function onNodeSelect(nodeID) {
		console.log('Selected node:', selectedNode);
        const sessionToken = localStorage.getItem('sessionToken');
        const url = new URL('/api/node/getNodeToken', window.location.origin);
        url.searchParams.append('token', sessionToken);
        url.searchParams.append('nodeID', nodeID);
		try {
			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					Authorization: `Bearer ${sessionToken}`,
				},
			});
			const data = await response.json();
            const nodeToken = data.nodeToken;
            console.log(nodeToken);
            setNodeToken(nodeToken);
        } catch (error) {
            console.error('Error fetching node token:', error.message);
            console.log('Cannot get node token');
        }
    }

	async function onProjectUpload(e) {
		e.preventDefault();

		if (!file) {
		  setSuccessfullyUploaded('Please select a file before submitting.');
		  return;
		}
	
		const formData = new FormData();
		formData.append('file', file);
		const sessionToken = localStorage.getItem('sessionToken');
	
		try {
		  const url = new URL('/api/r2/postR2Object', window.location.origin);
		  url.searchParams.append('domain', window.location.origin);
		  const response = await fetch(url, {
			method: 'POST',
			body: formData,
			headers: {
			  'Authorization': `Bearer ${sessionToken}`
			}
		  });

		  const result = await response.json();
		  setFileURL(result.url);
	
		  if (response.ok) {
			setSuccessfullyUploaded('File uploaded successfully!');
		  } else {
			const errorText = await response.text();
			setSuccessfullyUploaded(`Failed to upload file: ${errorText}`);
			console.log(errorText);
		  }
		} catch (error) {
		  setMessage(`Error: ${error.message}`);
		}
	}

	async function onNodePush(e) {
		e.preventDefault(); // Prevent form submission

		if (!selectedNode) {
			alert('Please select a node before pushing the project.');
			return;
		}

		try {
			const sessionToken = localStorage.getItem('sessionToken');
			const url = new URL('https://durable-object-starter.10951246.workers.dev');
			url.searchParams.append('token', sessionToken);
			const response = fetch(url, {
				method: 'PUT',
				headers: {
					'Authorization': `Bearer ${sessionToken}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					message: fileURL,
					token: nodeToken,
				}),
			})

			if (response.ok) {
				setSuccessfullyUploaded('File uploaded successfully!');
			} else {
				const errorText = await response.text();
				setSuccessfullyUploaded(`Failed to upload file: ${errorText}`);
				console.log(errorText);
			}
		} catch (error) {
			console.error('Error pushing project to node:', error);
		}
	}

	return (
		<>
			<div class="navBar">
					<img src={logo} alt="Company Logo" height="100px"/>
					<button class="navButton" onClick={navToDashboard}>Home</button>
					<button class="navButton" onClick={navToNodeEnroll}>Displays</button>
					<button class="navButton" onClick={navToProjectSetup}>Projects</button>
				</div>
			<div className="body-box">
				<div className="setup-box">
					<h2>Upload a file</h2>
					<form className="form" action="" method="">
						<div>
							<input style={{margin:"auto 0"}}type="file" onChange={(e) => setFile(e.target.files[0])} />
							{/* <input value={projectName} placeholder="File Name" onChange={(ev) => setProjectName(ev.target.value)} /> */}
						</div>
						<input onClick={onProjectUpload} className="inputButton" type="button" value="Upload" />
					</form>
					<div>{successfullyUploaded}</div>
					<h2 style={{margin: "10px"}}>Choose a Node</h2>
					<form className="form" action="" method="">
						<select 
							value={selectedNode} 
							onChange={(e) => {
								setSelectedNode(e.target.value);
								onNodeSelect(e.target.value);
							}} 
							className="user-box"
						>
							<option value="">--Select a node--</option>
							{nodes.map((node) => (
								<option key={node.id} value={node.id}>
									{node.name}
								</option>
							))}
						</select>
						<input onClick={onNodePush} className="inputButton" type="button" value="Push to Node" />
					</form>
				</div>
			</div>
		</>
	);
}

export default ProjectSetup;
