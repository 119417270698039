import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './assets/logo.png';
import './Login.css';

function NodeEnroll() {
    const [code, setCode] = useState("");
    const [successfullyEnrolled, setSuccess] = useState("");
    const [nodeName, setName] = useState("");
    const sessionToken = localStorage.getItem('sessionToken');
    const navigate = useNavigate();

    const navToDashboard = () => {
        navigate('/Dashboard/Landing');
    };

    const navToNodeEnroll = () => {
        navigate('/nodeEnroll');
    };

    const navToProjectSetup = () => {
        navigate('/setup')
    }

    const navToLogin = () => {
        navigate('/Login');
    };

    // Check the session token when the component loads
    useEffect(() => {
        const sessionToken = localStorage.getItem('sessionToken');
        if (sessionToken) {
            const url = new URL('/api/auth/sessionVerify', window.location.origin);
            url.searchParams.append('token', sessionToken);

            fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json' },
        })
        .then(response => response.json())
        .then(data => {
            if (data.expired == true) {
                // Token is expired; navigate to the login page
                console.log("Session expired, please log in again.");
                navToLogin();
            } 
        })
        .catch(error => {
            console.error("Error verifying session: ", error);
        });
        }
    }, []);

    function onButtonClick(e) {
        e.preventDefault(); // Prevent form submission

        if (code === '') {
            setSuccess("No code provided");
            return; // Exit the function early if no code
        }

        const url = new URL('/api/node/nodeEnrollment_Cloud', window.location.origin);
        url.searchParams.append('code', code);
        url.searchParams.append('nodeName', nodeName);
        url.searchParams.append('sessionToken', sessionToken);
        url.searchParams.append('domain', window.location.origin);

        fetch(url, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${sessionToken}` 
            },
        })
        .then(response => {
            if (response.status === 406) {
                console.log("Token expired, navigating back to login page.")
                navToLogin();
            }
            return response.json();
        })
        .then(data => {
            console.log(data);
            if (data.message === "Node created successfully") {
                setSuccess("Screen is Enrolled!");
            } else {
                setSuccess("Code appears to be invalid");
            }
        })
        .catch(error => {
            console.error(error);
            setSuccess("An error occurred while enrolling the screen.");
        });        
    }

    return (
        <>
            <div class="navBar">
                <img src={logo} alt="Company Logo" height="100px"/>
                <button class="navButton" onClick={navToDashboard}>Home</button>
                <button class="navButton" onClick={navToNodeEnroll}>Displays</button>
                <button class="navButton" onClick={navToProjectSetup}>Projects</button>
            </div>
            <div className='body-box'>
                <div className="login-box">
                    <h2>TYPE IN YOUR CODE</h2>
                    <form action="" method="">
                        <div className="user-box">
                            <input  
                                value={code}
                                placeholder='Screen Code' 
                                onChange={ev => setCode(ev.target.value)}
                                className="user-box"      
                            />  
                        </div>
                        <div className="user-box">
                            <input  
                                value={nodeName}
                                placeholder='Name of Screen' 
                                onChange={ev => setName(ev.target.value)}
                                className="user-box"      
                            />  
                        </div>
                        <input 
                            onClick={onButtonClick}
                            className="inputButton"
                            type="button"
                            value="Submit"
                        />  
                    </form>
                    <div>
                        {successfullyEnrolled}
                    </div>
                </div>
            </div>
        </>
    );
}

export default NodeEnroll;