import React, { useEffect, useState } from 'react';

function WebSocketComponent() {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [ws, setWs] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('nodeToken');
        const socket = new WebSocket(`wss://durable-object-starter.10951246.workers.dev/?token=${token}`);
        setWs(socket);

        socket.onmessage = (event) => {
            setMessages((prevMessages) => [...prevMessages, event.data]);
        };

        socket.onclose = (event) => {
            console.log('WebSocket closed:', event.code, event.reason);
        };

        socket.onerror = (event) => {
            console.error('WebSocket error:', event);
        };

        return () => {
            socket.close();
        };
    }, []);

    const sendMessage = () => {
        if (ws && input) {
            ws.send(input);
            setInput('');
        }
    };

    return (
        <div>
            <h1>WebSocket Messages</h1>
            <ul>
                {messages.map((message, index) => (
                    <li key={index}>{message}</li>
                ))}
            </ul>
            <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
            />
            <button onClick={sendMessage}>Send</button>
        </div>
    );
}

export default WebSocketComponent;
