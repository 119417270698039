import React, { useEffect, useState } from 'react';
import placeHolderImage from './assets/PlaceHolder.png';
import './display.css';

function WebSocketComponent() {
    const [URL, setURL] = useState(null);
    const [input, setInput] = useState('');
    const [ws, setWs] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('nodeToken');
        const socket = new WebSocket(`wss://durable-object-starter.10951246.workers.dev/?token=${token}`);
        setWs(socket);

        socket.onmessage = (event) => {
            setURL(event.data);
        };

        socket.onclose = (event) => {
            console.log('WebSocket closed:', event.code, event.reason);
        };

        socket.onerror = (event) => {
            console.error('WebSocket error:', event);
        };

        return () => {
            socket.close();
        };
    }, []);

    const sendMessage = () => {
        if (ws && input) {
            ws.send(input);
            setInput('');
        }
    };

    return (
        <div className="media-container">
            <img src={URL || placeHolderImage} alt="Content" />
        </div>
        
        
    );
}

export default WebSocketComponent;
