import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  let rememberMe = false;
  const navigate = useNavigate();

  const navToRegistration = () => {
    navigate('/register');
  };
  
  const navToDashboard = () => {
    navigate('/Dashboard/Landing');
  };

  const onCheckClick = () => {
    rememberMe = true;
  };

  // Check the session token when the component loads
  useEffect(() => {
    const sessionToken = localStorage.getItem('sessionToken');
    if (sessionToken) {
        const url = new URL('/api/auth/sessionVerify', window.location.origin);
        url.searchParams.append('token', sessionToken);

        fetch(url, {
        method: 'GET',
        headers: { 'Accept': 'application/json' },
      })
      .then(response => response.json())
      .then(data => {
        if (data.expired) {
          // Token is expired; navigate to the login page
          console.log("Session expired, please log in again.");
        } else {
          // Token is still valid; navigate to the dashboard
          navToDashboard();
        }
      })
      .catch(error => {
        console.error("Error verifying session:", error);
      });
    }
  }, []);

  const onButtonClick = () => {
    setEmailError("");
    setPasswordError("");

    if (email === "") {
      setEmailError("Please enter your email");
      return;
    }

    if (password === "") {
      setPasswordError("Please enter a password");
      return;
    }

    const url = new URL('/api/auth/loginVerify', window.location.origin);
    url.searchParams.append('email', email);
    url.searchParams.append('password', password);
    url.searchParams.append('rememberMe', rememberMe);

    fetch(url, {
      method: 'GET',
      headers: { 'Accept': 'application/json' },
    })
    .then(response => response.json())
    .then(data => {
      if (data.token) {
        localStorage.setItem('sessionToken', data.token);
        navToDashboard();
      } else {
        setPasswordError("Invalid email or password");
      }
    })
    .catch(error => {
      setPasswordError("Invalid email or password");
    });
  };

  return (
    <div className='body-box'>
      <div className="login-box">
        <h2>LOGIN</h2>
        <form action="" method="">
          <div className="user-box">
            <input
              value={email}
              placeholder='Username or Email'
              onChange={ev => setEmail(ev.target.value)}
              className="user-input"
            />
            <label className='errorLabel'>{emailError}</label>
          </div>
          <div className="user-box">
            <input
              value={password}
              placeholder='Password'
              onChange={ev => setPassword(ev.target.value)}
              className='pass-input'
              type='password'
            />
            <label className='errorLabel'>{passwordError}</label>
          </div>
          <input
            onClick={onCheckClick}
            className="inputCheck"
            name='check'
            id='check'
            type="checkbox"
            value="check"
          />
          <label className='checkLabel'>Remember Me</label><br></br>
          <input
            onClick={onButtonClick}
            className="inputButton"
            type="button"
            value="Submit"
          />
        </form>
        <div>
          <button class="navButton" onClick={navToRegistration}>Register this Screen</button>
        </div>
      </div>
      <div className="picture-box"></div>
    </div>
  );
}

export default Login;