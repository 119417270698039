import React, { useState } from 'react';
import './LandingTree.css'

let allNodes = new Array();
let allProjects = new Array();

function verifySession(){
  const sessionToken = localStorage.getItem('sessionToken'); 
  if (sessionToken) { 
    const url = new URL('/api/auth/sessionVerify', 
    window.location.origin); 
    url.searchParams.append('token', sessionToken); 

    fetch(url, { 
    method: 'GET', 
    headers: { 'Accept': 'application/json' }, 
    }) 
    .then(response => response.json()) 
    .then(data => { 
      if (data.expired == true) { 
        // Token is expired; navigate to the login page 
        console.log("Session expired, please log in again."); 
        navToLogin(); 
      }  
    }) 
    .catch(error => { 
        console.error("Error verifying session: ", error); 
    }); 
  }
}
  

// This is the call to the worker to get the node heirarchy 
const addNodes = async () => {
  const t = localStorage.getItem("sessionToken");
  const level = 2;
  const headId = '';
  const url = new URL('/api/node/getNodeHierarchy', window.location.origin);
  url.searchParams.append('token', t);
  url.searchParams.append('levels', level);
  url.searchParams.append('headID', headId);

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${t}`
      },
    });
    const data = await response.json();
    console.log(data);

    // puts the pulled data to a new array that contains the objed, sets parent to root node
    const newNode = [{
      type: data.type,
      id: data.id,
      name: data.name,
      root: "rootNode",
      children: data.children,
    }];

    addChild(newNode);
  } catch (error) {
    console.error("Error fetching hierarchy:", error);
    console.log("Cannot get hierarchy");
  }
};

// Data for the default root node
const NodeRootData = [
  {
    type: "dir",
    id: -1,
    name: "Nodes",
    root: "node",
    children: []
  }
]
// data for the default root project
const ProjectRootData = [
  {
    type: "dir",
    id: -2,
    name: "Projects",
    root: "project",
    children: []
  }
]

// Tree is called only when creating the outermost parent to apear on the page

const Tree = ({ data }) => {
  // Code below is not being used this is what was being used to track node parents
  // and to filter out the two default Tree nodes

  /*
  let id = -99
  let root = ""
  {data.map((m) => (
    id = m.id
  ))}
  {data.map((m) => (
    root = m.root
  ))}
  if(allNodes.length == 0 && root == "node")
    allNodes.push(data)

  for(let i = 0; i < allNodes.length; i++){
    let arrId = -1
    let arrRoot = ""
    {allNodes[i].map((m) => (
      arrId = m.id
    ))}
    {data.map((m) => (
      root = m.root
    ))}
    if(arrId != id && root == arrRoot){
      allNodes.push(data)
    }
  }

  if(allProjects.length == 0 && root == "project")
    allProjects.push(data)

  for(let i = 0; i < allProjects.length; i++){
    let arrId = -99
    let arrRoot = ""
    {allProjects[i].map((m) => (
      arrId = m.id
    ))}
    {data.map((m) => (
      root = m.root
    ))}
    if(arrId != id && root == arrRoot){
      allProjects.push(data)
    }
  }
  */

  // creating the two default parent nodes to default for all projects and nodes
  let root = ""
  {data.map((m) => (
    root = m.root
  ))}
  if(root == "node")
    allNodes.push(data)
  if(root == "project")
    allProjects.push(data)

  // return creates a tree node while passing in the node data to map to its id
  return (
    <ul>
      {data.map((node) => (
        <TreeNode key={node.id} node={node}/>
      ))}
    </ul>
  );
};

// Treenode is all the nodes to create a hierarchy data for all the inner nodes
const TreeNode = ({ node }) => {

  // each node is an array so it has to pull out the data
  let currId = 0
  let currType = ""
  let currName = ""
  let currChildren = []
  if(node.map){
    {node.map((m) => (
      currId = m.id
    ))}
    {node.map((m) => (
      currType = m.type
    ))}
    {node.map((m) => (
      currName = m.name
    ))}
    {node.map((m) => (
      currChildren = m.children
    ))}
  }
  else{
    currId = node.id
    currType = node.type
    currName = node.name
    currChildren = node.children
  }
 
  const [isOpen, setIsOpen] = useState(false);

  // returns an li item that is actually a button so that it displays its name
  // alsong with the arrow to open up when clicked on. It then creates a new treenode
  // recursivly for all of the data passed in.

  return (
    <>
      <li>
        {currType === "dir" && (
          <button class="tree-item" onClick={ev=>setIsOpen(!isOpen)}>
            {isOpen ? "v" : ">"} {currName}
          </button>
        )}
        {currType === "node" && <span>{currName}</span>}
        {isOpen && currChildren && currChildren.length > 0 && (
          <ul>
            {currChildren.map((child) => (
              <TreeNode key={child.id} node={child}/>
            ))}
          </ul>
        )}
      </li>
    </>
  )
}

// add child gets the obj to be pushed into the parent obj
const addChild = (childObj) =>{
  let parent = getParent(childObj)
  let parentChildren = []
  {parent.map((m) => (
    parentChildren = m.children
  ))}
  parentChildren.push(childObj)
}


// finds the parent for either the project or node that is to be displayed in the heirarchy
const getParent = (objToAdd) =>{
  let objRoot = -99
 {objToAdd.map((m) => (
    objRoot = m.root
  ))}
  // finding if parent exist and then adds to all nodes and returns parent node
  for(let i = 0; i < allNodes.length; i++){
    let curentId = -1
    {allNodes[i].map((m) => (
      curentId = m.id
    ))}
    // check for the preset root node to add
    if(objRoot == "rootNode" && curentId == -1){
      allNodes.push(objToAdd)
      return allNodes[i]
    }
    if(curentId == objRoot){
      allNodes.push(objToAdd)
      return allNodes[i]
    }
  }
  // finding if parent exist and then adds to all projects and returns parent projcet
  for(let i = 0; i < allProjects.length; i++){
    let curentId = -1
    {allProjects[i].map((m) => (
      curentId = m.id
    ))}
    // check for the preset root project to add
    if(objRoot == "rootProject" && curentId == -2){
      allProjects.push(objToAdd)
      return allProjects[i]
    }
    if(curentId == objRoot){
      allProjects.push(objToAdd)
      return allProjects[i]
    }
  }
  throw ("can't find parent") 
}

// Main called function that creates the two default parents and then
// calls addNodes to get the data for all the nodes we want.
function LandingTree(){
  const tree1 = <Tree data={NodeRootData}></Tree>;
  const tree2 = <Tree data={ProjectRootData}></Tree>;
  verifySession()
  addNodes()
  return (
    <>
      <div className='tree-container'>
        {tree1}
        {tree2}    
      </div>
    </>
  )
}
export default LandingTree